import React from "react"

import {
  ContentLibrarySideInnerWrapper,
  ContentLibrarySideWrapper
} from "../styled/pages/about/content-library/ContentLibraryComponents"

const HighlightedCard = ({ children}) => {
  return (
    <ContentLibrarySideWrapper>
      <ContentLibrarySideInnerWrapper padding>
        {children}
      </ContentLibrarySideInnerWrapper>
    </ContentLibrarySideWrapper>
  )
}

export default HighlightedCard;