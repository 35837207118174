import styled from "styled-components";

export const ImageLogo = styled.img`
  cursor: pointer;
  width: 100%;
  height: ${props => props.logo && "100px"};
  @media screen and (max-width: 800px) {
    margin-top: 1rem;
  }
`

export const AuthorLogoWrapper = styled.div`
  border: 1px solid rgba(13, 65, 121, 0.5);
  border-radius: 50%;
  padding: 1rem;
  background: none;
  box-sizing: border-box;
`

export const AuthorLogo = styled.div`
  height: 12rem;
  width: 12rem;
  background-image: ${props => props.img && "url(" + props.img + ")"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`