import styled from "styled-components";
import {Body} from "../../../typography/Typography";

export const ContentLibraryMenuButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
`

export const ContentLibrarySideWrapper = styled.div`
  border: 1px solid rgba(13, 65, 121, 0.5);
  padding: ${props => props.nopadding ? "0" : "15px"};
  background: none;
  margin-bottom: ${props => props.nomargin ? "0" : "0.5rem"};
  box-sizing: border-box;
  margin-top: ${props => props.top ? "40px" : "unset"};
`

export const ContentLibrarySideInnerWrapper = styled.div`
  background-color: rgba(13, 65, 121, 0.7);
  height: auto;
  display: grid;
  padding: ${props => props.padding ? "20px" : "0"};
`

export const ContentLibraryMenuItem = styled(Body)`
  color: ${props => props.active ? "white" : "var(--body-text)"};
  margin-top: 5px;
  padding-left: 5px;
  transition: color 0.1s;
  
  &:hover {
    color: white;
  }
`

export const CardsSection = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export const CLChipWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  padding: 0 1.1rem 0 1.1rem;
`

export const CLChipInline = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 0.5rem 0.5rem;
  @media screen and (max-width: 600px){
    grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
    grid-template-rows: auto auto;
  }
`

export const CLChipInlineDelete = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
`