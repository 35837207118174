import React from "react"
import { Helmet } from "react-helmet"

const TrackingScripts = ({ allowed }) => {
  return allowed ? (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-8Y7GGTT83S" />
      <script type="text/javascript" src="/tracking/gtag.js" async />
    </Helmet>
  ) : null
}

export default TrackingScripts