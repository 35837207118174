import styled from "styled-components";
import Lottie from "lottie-react";

export const LottieAnimation = styled(Lottie)`
  width: 400px;
  height: 400px;
  position: absolute;
  margin-top: -100px;

  @media screen and (max-width: 1200px) {
      opacity: 0.7;
      width: 150px !important;
  }

  ${props => {
    if (props.left) {
      return `
        width: 300px;
    `
    } else if (props.right) {
      return `
        transform: scaleY(-1);
        right: 0;
    `
    } else if (props.turned) {
      return `
        width: 300px;
        transform: scaleX(-1);
        right: 0;
        margin-top: -80px;
    `
    }
  }}
`