import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Markdown from "markdown-to-jsx"

import Seo from "../components/general/seo/SEO"
import SideNavigation from "../components/general/side-navigation/SideNavigation"
import SectionLayoutGrid from "../components/layouts/SectionLayoutGrid"
import HighlightedCard from "../components/cards/HighlightedCard"
import ToggleCard from "../components/cards/ToggleCard"
import PressPostCard from "../components/cards/PressPostCard"

import Col from "../components/styled/grid/Col"
import { Body, Header2, Header3, Span, Title } from "../components/styled/typography/Typography"
import { AboutWrapper, HeroWrapper } from "../components/styled/pages/solutions/SolutionsComponents"
import { InlineGrid } from "../components/styled/grid/Grids"
import { OnlyDesktop, OnlyMobile } from "../components/styled/views/MobileOrDesktop"
import { AuthorLogo, AuthorLogoWrapper, ImageLogo } from "../components/styled/pages/products/header/HeaderComponents"
import { LottieAnimation } from "../components/styled/lotti/LottieAnimations"
import { ButtonA, ButtonContainer } from "../components/styled/button/ButtonComponents"

import lottie1 from "../../static/assets/animations/circuit/circuit01.json"
import lottie2 from "../../static/assets/animations/circuit/circuit02.json"
import TrackingScripts from "../components/general/cookie-panel/TrackingScripts"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    {
      site: strapiDheatLanding {
          dheat_hero {
            title_color
            title_white
            quote
            highlighted_title
            highlighted_description
            hero_image {
              url
            }
          }
          dheat_related_posts {
            title
            related1_slug
            related2_slug
            related3_slug
          }
          dheat_header {
            title
            description
          }
          dheat_header2 {
            title
            description
          }
          dheat_faq {
            title
            cards {
              id
              title
              description
            }
          }
          dheat_author {
            title
            name
            job_title
            description
            contact_email
            picture {
              url
            }
          }
          dheat_about {
            title
            description
            logo {
              url
            }
          }
          SEO {
            title
            keywords
            description
            isIndexable
            preview {
              url
            }
          }
      }
      allStrapiBlogposts {
        blog_posts:nodes {
          date(formatString: "LL", locale: "en")
          slug
          short_description
          title
          avatar {
            url
          }
        }
      }
    }
  `)

  const { site } = data
  const { blog_posts } = data.allStrapiBlogposts

  const related1 = blog_posts.find(item => item.slug === site.dheat_related_posts.related1_slug)
  const related2 = blog_posts.find(item => item.slug === site.dheat_related_posts.related2_slug)
  const related3 = blog_posts.find(item => item.slug === site.dheat_related_posts.related3_slug)

  return (
    <>
      <TrackingScripts allowed={true}/>
      <SideNavigation />
      <HeroWrapper>
        <Seo
          description={site.SEO.description}
          title={site.SEO.title}
          image={site.SEO.preview.url}
          lang={"en"}
          keywords={site.SEO.keywords}
          isIndexable={site.SEO.isIndexable}
        />
        <SectionLayoutGrid id="hero" hero={site.dheat_hero.hero_image.url}>
          <Col border>
            <Title color="primary-brand" marginBottom="small">
              {site.dheat_hero.title_color}
              <Span color="white">
                {site.dheat_hero.title_white}
              </Span>
            </Title>
            <Header2 small color="primary-brand" italic light>
              {site.dheat_hero.quote}
            </Header2>
          </Col>
        </SectionLayoutGrid>
        <SectionLayoutGrid id="problem" customBackground="none">
          <Col>
            <HighlightedCard>
              <InlineGrid>
                <Col>
                  <Header3 color="primary-brand" marginBottom="small">
                    {site.dheat_hero.highlighted_title}
                  </Header3>
                </Col>
                <Col className="markdown">
                  <Markdown children={site.dheat_hero.highlighted_description} />
                </Col>
              </InlineGrid>
            </HighlightedCard>
          </Col>
        </SectionLayoutGrid>
      </HeroWrapper>
      <LottieAnimation
        left="true"
        animationData={lottie1}
      />
      <SectionLayoutGrid id="solution" customBackground="middle" customMinHeight="large">
        <Col>
          <Header2 color="white" marginBottom="normal">
            {site.dheat_header.title}
          </Header2>
          <div className="markdown">
            <Markdown children={site.dheat_header.description} />
          </div>
        </Col>
      </SectionLayoutGrid>
      <SectionLayoutGrid id="solution2" customBackground="middle" customMinHeight="large">
        <Col>
          <Header2 color="white" marginBottom="normal">
            {site.dheat_header2.title}
          </Header2>
          <div className="markdown">
            <Markdown children={site.dheat_header2.description} />
          </div>
        </Col>
      </SectionLayoutGrid>
      <SectionLayoutGrid id="faq" customBackground="middle" customMinHeight="large" customGap>
        <Col span={6} center>
          <Header2 color="white" marginBottom="normal">
            {site.dheat_faq.title}
          </Header2>
        </Col>
        {site.dheat_faq.cards.map((item) => (
          <Col key={item.id}>
            <ToggleCard
              title={item.title}
              description={item.description}
            />
          </Col>
        ))}
      </SectionLayoutGrid>
      <LottieAnimation
        turned="true"
        animationData={lottie2}
      />
      <SectionLayoutGrid id="related" customBackground="middle" customMinHeight="large">
        <Col>
          <Header2 color="white" marginBottom="normal">
            {site.dheat_related_posts.title}
          </Header2>
        </Col>
        {related1 &&
        <Col span={4}>
          <PressPostCard
            title={related1.title}
            slug={"https://balasys.eu/blogs/" + related1.slug}
            avatar={related1.avatar.url}
            date={related1.date}
            short_description={related1.short_description}
          />
        </Col>
        }
        {related2 &&
        <Col span={4}>
          <PressPostCard
            title={related2.title}
            slug={"https://balasys.eu/blogs/" + related2.slug}
            avatar={related2.avatar.url}
            date={related2.date}
            short_description={related2.short_description}
          />
        </Col>
        }
        {related3 &&
        <Col span={4}>
          <PressPostCard
            title={related3.title}
            slug={"https://balasys.eu/blogs/" + related3.slug}
            avatar={related3.avatar.url}
            date={related3.date}
            short_description={related3.short_description}
          />
        </Col>
        }
      </SectionLayoutGrid>
      <SectionLayoutGrid id="author" customBackground="middle" customMinHeight="large">
        <Col>
          <Header2 color="white" marginBottom="normal">
            {site.dheat_author.title}
          </Header2>
        </Col>
        <Col span={3} center grid>
          <AuthorLogoWrapper>
            <AuthorLogo img={site.dheat_author.picture.url} />
          </AuthorLogoWrapper>
        </Col>
        <Col span={9} center grid>
          <Header3 color="white" marginBottom="smaller">
            {site.dheat_author.name}
          </Header3>
          <Body color="body-text" marginBottom="small">
            {site.dheat_author.job_title}
          </Body>
          <div className="markdown">
            <Markdown children={site.dheat_author.description} />
          </div>
          <ButtonContainer>
            <ButtonA color="primary-brand" href={"mailto:" + site.dheat_author.contact_email}>
              Contact
            </ButtonA>
          </ButtonContainer>
        </Col>
      </SectionLayoutGrid>
      <AboutWrapper>
        <SectionLayoutGrid customBackground="none" customMinHeight="small">
          <Col span={6} grid center>
            <Header2 color="primary-brand" marginBottom="small" small>
              {site.dheat_about.title}
            </Header2>
            <OnlyMobile>
              <Col marginBottom="medium" center>
                <ImageLogo alt={site.dheat_about.title}
                           src={site.dheat_about.logo.url}
                           className="image-for-modal" logo />
              </Col>
            </OnlyMobile>
            <div className="markdown">
              <Markdown children={site.dheat_about.description} />
            </div>
          </Col>
          <Col span={6} grid center>
            <OnlyDesktop>
              <ImageLogo alt={site.dheat_about.title}
                         src={site.dheat_about.logo.url}
                         className="image-for-modal" logo />
            </OnlyDesktop>
          </Col>
        </SectionLayoutGrid>
      </AboutWrapper>
    </>
  )
}

export default IndexPage
