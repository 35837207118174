export const handleTextAlign = textAlign => {
    switch (textAlign) {
        case "left":
            return "left";
        case "center":
            return "center";
        case "right":
            return "right";
        default:
            return "";
    }
};

export const handleMarginBottom = marginBottom => {
    switch (marginBottom) {
        case "smallest":
            return "var(--smallest)";
        case "smaller":
            return "var(--smaller)";
        case "small":
            return "var(--small)";
        case "normal":
            return "var(--normal)";
        case "medium":
            return "var(--medium)";
        case "large":
            return "var(--large)";
        case "larger":
            return "var(--larger)";
        case "largest":
            return "var(--largest)";
        default:
            return "";
    }
};

export const handleMarginTop = marginTop => {
    switch (marginTop) {
        case "smallest":
            return "var(--smallest)";
        case "smaller":
            return "var(--smaller)";
        case "small":
            return "var(--small)";
        case "normal":
            return "var(--normal)";
        case "medium":
            return "var(--medium)";
        case "large":
            return "var(--large)";
        case "larger":
            return "var(--larger)";
        case "largest":
            return "var(--largest)";
        default:
            return "";
    }
};

export const handlePadding = padding => {
    switch (padding) {
        case "small":
            return "0 0.5rem 0 0.5rem";
        case "medium":
            return "0 1.25rem 0 1.25rem";
        case "large":
            return "0 2.5rem 0 2.5rem";
        default:
            return "";
    }
};

export const handleColor = color => {
    return "var(--" + color + ")"
};