import styled from "styled-components"

const handlePadding = padding => {
  switch (padding) {
    case "noTop":
      return "0 0 8vh 0"
    case "bigTop":
      return "24vh 0 8vh 0"
    case "noBottom":
      return "8vh 0 0 0"
    case "smallerTop":
      return "3vh 0 8vh 0"
    default:
      return "6rem 0 6rem 0"
  }
}

const handleMinHeight = mh => {
  switch (mh) {
    case "small":
      return "20vh"
    case "medium":
      return "40vh"
    case "large":
      return "55vh"
    case "larger":
      return "70vh"
    default:
      return "30vh"
  }
}

const handleBackground = bg => {
  switch (bg) {
    case "none":
      return "unset"
    case "middle":
      return "var(--bg-gradient--middle)"
    default:
      return "var(--bg-radial-gradient)"
  }
}

export const Background = styled.div`
  background: ${({ customBg }) => handleBackground(customBg)};

  background-repeat: ${props => props.hero ? "no-repeat" : ""};
  background-position: ${props => props.hero ? "top right" : ""};
  background-color: ${props => props.hero ? "transparent" : ""};
  background-size: ${props => props.hero ? "auto" : ""};
  background-image: ${props => props.hero ? "url('" + props.hero + "') !important" : ""};
`

export const LayoutGrid = styled.div`
  display: grid;
  width: ${props => props.wide ? "80vw" : "60vw"};
  margin: ${props => props.contactMargin ? "50vh auto auto auto" : "auto"};
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${props => props.customGap ? "1rem 2rem" : "2.5rem 2.5rem"};
  padding: ${({ customPadding }) => handlePadding(customPadding)};
  min-height: ${({ minHeight }) => handleMinHeight(minHeight)};
  align-content: ${props => props.alignStart ? "start" : "center"};

  @media screen and (max-width: 1400px) {
    width: ${props => props.wide ? "80vw" : "65vw"};
  }

  @media screen and (max-width: 1100px) {
    width: ${props => props.wide ? "85vw" : "75vw"};
    margin: ${props => props.contactMargin ? "10vh auto auto auto" : "auto"};
  }

  @media screen and (max-width: 800px) {
    min-height: ${props => props.hero ? "80vh" : "auto" };
    width: 90%;
    grid-template-columns: 1fr;
    margin: auto;
  }
`

export const InlineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem 1rem;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem 1rem;
  }
`