import React from "react"
import Markdown from "markdown-to-jsx"

import { Header3 } from "../styled/typography/Typography"
import { ContentLibrarySideWrapper } from "../styled/pages/about/content-library/ContentLibraryComponents"
import { ToggleButton, ToggleCardContent, ToggleCardWrapper } from "../styled/pages/solutions/SolutionsComponents"

import ToggleIcon from "../../../static/assets/icon_down_arrow.svg"

const ToggleCard = ({ title, description }) => {
  return (
    <ContentLibrarySideWrapper nomargin="true">
      <ToggleCardWrapper>
        <summary>
          <Header3 color="body-text">{title}</Header3>
          <ToggleButton>
            <img src={ToggleIcon} alt="toggle" />
          </ToggleButton>
        </summary>
        <ToggleCardContent className="markdown">
          <Markdown children={description}/>
        </ToggleCardContent>
      </ToggleCardWrapper>
    </ContentLibrarySideWrapper>
  )
}

export default ToggleCard