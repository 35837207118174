import styled from "styled-components"

import { handleMarginBottom, handleMarginTop } from "../../general/typography/TypographyFunctions"

const handleGridColumn = gridColumn => {
  switch (gridColumn) {
    case 1:
      return "span 1"
    case 2:
      return "span 2"
    case 3:
      return "span 3"
    case 4:
      return "span 4"
    case 5:
      return "span 5"
    case 6:
      return "span 6"
    case 7:
      return "span 7"
    case 8:
      return "span 8"
    case 9:
      return "span 9"
    case 10:
      return "span 10"
    case 11:
      return "span 11"
    default:
      return "1/-1"
  }
}

const Col = styled.div`
  align-self: ${props => props.center ? "center" : ""};
  align-content: ${props => props.verticalcenter ? "center" : ""};
  justify-self: ${props => props.center ? "center" : "start"};
  justify-content: ${props => props.center ? "center !important" : "unset"};
  display: ${props => props.grid ? "grid" : ""};
  margin-bottom: ${({ marginBottom }) => handleMarginBottom(marginBottom)};
  margin-top: ${({ marginTop }) => handleMarginTop(marginTop)};
  width: 100%;
  grid-column: ${({ span }) => handleGridColumn(span)};
  border-left: ${props => props.border && "5px solid var(--primary-brand)"};
  padding-left: ${props => props.border && "1rem"};

  @media screen and (max-width: 800px) {
    grid-column: 1/-1 !important;
  }
`

export default Col