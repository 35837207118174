import React from "react";

import {Background, LayoutGrid} from "../styled/grid/Grids";

const SectionLayoutGrid = ({children, customBackground, id, start, customPadding, customMinHeight, hero, contact, wide, customGap, solutions}) => {

    return (
        <Background id={id} customBg={customBackground} hero={hero} solutions={solutions}>
            <LayoutGrid alignStart={start} customPadding={customPadding} minHeight={customMinHeight} contactMargin={contact} wide={wide} customGap={customGap} hero={hero}>
                {children}
            </LayoutGrid>
        </Background>
    )
}

export default SectionLayoutGrid;