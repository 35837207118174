import React from "react"
import {Helmet} from "react-helmet"
import { useLocation } from "@reach/router"

import favicon from "/src/images/dheat_favicon32x32.png"
import defaultImage from "/src/images/Post_image_logo.png"

const Seo = ({lang, title, description, keywords, image, isBlogPost, isIndexable}) => {

    const { pathname } = useLocation()
    const defaultUrl = "https://dheatattack.com/";

    return (
        <Helmet htmlAttributes={{lang: lang}}>
            {/* General tagek, az alap seóhoz */}
            <title>{title}</title>
            {description && <meta name="description" content={description}/>}
            {keywords && <meta name="keywords" content={keywords}/>}
            <link rel="canonical" href={defaultUrl + pathname}/>
            <link rel="icon" href={favicon} sizes="any" type="image/svg+xml"/>
            {/* Ha nem kell googleban indexelni */}
            {!isIndexable && <meta name="robots" content="noindex" />}
            {/* OpenGraph tagek, a social mediához */}
            <meta property="og:title" content={title}/>
            <meta property="og:url" content={defaultUrl + pathname}/>
            {image ? <meta name="image" property="og:image" content={image}/> : <meta name="image" property="og:image" content={defaultUrl + defaultImage}/>}
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="630"/>
            {isBlogPost ? <meta property="og:type" content="article"/> : <meta property="og:type" content="website"/>}
            {description && <meta property="og:description" content={description}/>}
            <meta property="og:locale" content={lang}/>
        </Helmet>
    )
}

export default Seo