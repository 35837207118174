import styled from "styled-components";
import {Link} from "gatsby";

export const PressPostCardWrapper = styled.a`
  display: grid;
  align-self: stretch;
  align-content: start;
  background-color: #173f6c;
  border-radius: 3px;
  box-shadow: 16px 16px 48px rgba(0, 0, 0, 0.1);
  transition: box-shadow 500ms;
  text-decoration: none;
  animation: fade-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  grid-template-rows: minmax(auto, 200px) auto minmax(60px, max-content) minmax(60px, max-content);

  @keyframes fade-in-left {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &:hover {
    box-shadow: 0px 0px 19px 0px #4fa0e2;
  }

  @media screen and (max-width: 800px) {
    grid-template-rows: minmax(auto, 200px) auto auto auto;
  }

`

export const PressPostCardImg = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--primary-brand);
  background-image: ${props => props.avatar ? "url(" + props.avatar + ")" : "unset"};
`

export const PressPostCardDate = styled.div`
  display: grid;
  background: var(--primary-brand);
  color: white;
  opacity: 0.8;
  align-content: center;
  justify-content: end;
  font-weight: 550;
  height: 30px;
  margin-top: -30px;
  padding-right: 20px;
`

export const PressPostPinnedCardWrapper = styled(Link)`
  display: grid;
  align-self: stretch;
  justify-self: stretch;
  background-color: #173f6c;
  border-radius: 3px;
  box-shadow: 16px 16px 48px rgba(0, 0, 0, 0.1);
  transition: box-shadow 500ms;
  text-decoration: none;
  height: 100%;
  animation: fade-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  grid-template-columns: minmax(200px, 2fr) minmax(150px, 1fr);

  @keyframes fade-in-left {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &:hover {
    box-shadow: 0px 0px 19px 0px #4fa0e2;
  }
`

export const PressPostPinnedCardImg = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--primary-brand);
  background-image: ${props => props.avatar ? "url(" + props.avatar + ")" : "unset"};
`

export const PressPostPinnedTitleWrapper = styled.div`
  display: grid;
  background: var(--primary-brand);
  height: max-content;
  padding: 20px;
`

export const PressPostPinnedDescriptionWrapper = styled.div`
  display: grid;
  padding: 20px;
`

export const PressPostPinnedTextWrapper = styled.div`
  display: grid;
`